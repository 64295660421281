const fontMap = {
  m4c: 'Montserrat',
  oo: 'MorePro-CondBook',
  sgh: 'Roboto',
  rb: 'Trade-Gothic-LT-Cond',
  sv: 'Din-Cond'
}

const getFontFromStyle = (style: string) => fontMap[style] || 'sans-serif'

export default getFontFromStyle
