import { VMData } from 'vmdata'
import axios from 'axios'
import config from '../config'

const getQueryObject = (): any  => {
  const params = {}
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
  }
  return params
}

const getVMData = async (): Promise<VMData> => {
  const { hash } = getQueryObject()
  const response = await axios.get<VMData>(`${config.vmApiUrl}/api/virtualmirror?hash=${encodeURIComponent(hash)}`)

  return response.data
}

export const isValidModel = ({store, products}: VMData) => {
  return store && Array.isArray(products)
}

export default getVMData
