import production from './production.config'
import development from './development.config'

let conf: AppConfig

if (process.env.NODE_ENV === 'production') {
  conf = production
} else {
  conf = development
}

export default conf
