import { VMScreenshotReturn } from '../types/virtualMirror'
import { Product } from 'vmdata'
import config from '../config'
import getFontFromStyle from '../libs/getFontFromStyle'
import { pipe } from '../libs/utils'
const addTimestampToURL = (url: string): string => {
  const timestamp = new Date().valueOf()
  const separator = url.includes('?') ? '&' : '?'
  return `${url}${separator}t=${timestamp}`
}

const createImage = (src: string): Promise<HTMLImageElement> => {
  return new Promise(function(resolve, reject) {
    const img = new Image()
    img.crossOrigin = 'Anonymous'
    img.onload = () => resolve(img)
    img.onerror = reject
    img.src = src
  })
}

const getDefaultLogoPath = (code: string): string => {
  return `./images/${code}-logo.svg`
}

const getTextStyle = style => {
  const fonstSizes = {
    sgh: { fontSize: 0.05, lineHeight: 1.2 },
    default: { fontSize: 0.05, lineHeight: 1.2 },
  }
  return { ...fonstSizes.default, ...fonstSizes[style] } || fonstSizes.default
}

const getBrandLogoPosition = style => {
  const position = {
    oo: { w: 0.34, y: 0.02 },
    sgh: { w: 0.5, y: 0.08 },
    sv: { w: 0.55, y: 0.05 },
    default: { x: 1, y: 0.03, w: 0.3 },
  }
  return { ...position.default, ...position[style] } || position.default
}

const formatBrandName = (name: String) => {
  return name.toLocaleUpperCase()
}

const formatOOProductCode = ({ code, style }) => {
  return style === 'oo' ? { code: code.replace(/^W[G0]/gi, ''), style } : { code, style }
}

const formatDefaultProductCode = ({ code, style }) => {
  const newCode = code[0] === '0' ? code.substring(1) : code

  return { code: newCode, style }
}

const formatProductCode = (code: String, style: String) => {
  const { code: newCode } = pipe(
    formatOOProductCode,
    formatDefaultProductCode
  )({ code, style })

  return newCode
}

const createScreenshotCanvas = async (
  screenshot: VMScreenshotReturn,
  product: Product,
  style: string
): Promise<HTMLCanvasElement> => {
  const { thumbnailUrl, brand, code, colorCode } = product
  const font = getFontFromStyle(style)
  const noCacheBrandUrl = ['sv', 'rb', 'sgh', 'oo', 'to'].includes(style)
    ? getDefaultLogoPath(style)
    : addTimestampToURL(brand.logoUrl)
  const noCacheThumbnailUrl = addTimestampToURL(thumbnailUrl)

  const [brandLogoEl, thumbnailEl, screenshotEl] = await Promise.all(
    [noCacheBrandUrl, noCacheThumbnailUrl, screenshot.imgURI].map(createImage)
  )

  const baseWidth = Math.min(screenshot.width, config.imageMaxWidth)
  const margin = baseWidth * 0.05
  const screenshotX = margin
  const screenshotY = margin
  const screenshotW = baseWidth
  const screenshotH = (screenshot.height * baseWidth) / screenshot.width
  const canvasW = baseWidth * 1.1
  const canvasH = screenshotH + baseWidth * 0.45

  const { x: brandLogoXOffset, y: brandLogoYOffset, w: brandLogoWRatio } = getBrandLogoPosition(
    style
  )

  const brandLogoW = baseWidth * brandLogoWRatio
  const brandLogoH = (brandLogoW * brandLogoEl.naturalHeight) / brandLogoEl.naturalWidth
  const brandLogoX = baseWidth * brandLogoXOffset - brandLogoW + margin
  const brandLogoY =
    screenshotH +
    margin +
    (canvasH - (screenshotH + margin)) / 2 -
    brandLogoH / 2 +
    baseWidth * brandLogoYOffset

  const thumbnailX = margin * 0.12
  const thumbnailY = screenshotH + margin * 3
  const thumbnailW = baseWidth * 0.3
  const thumbnailH = (thumbnailW * thumbnailEl.naturalHeight) / thumbnailEl.naturalWidth

  const textX = margin
  const textY = margin + thumbnailY + thumbnailH

  const { fontSize, lineHeight } = getTextStyle(style)
  const textH = baseWidth * fontSize

  const cvs = document.createElement('canvas')
  cvs.width = canvasW
  cvs.height = canvasH
  const ctx = cvs.getContext('2d')

  // Backgorund
  ctx.fillStyle = 'white'
  ctx.fillRect(0, 0, canvasW, canvasH)
  console.log(style)
  // Product name
  ctx.font = `${textH}px ${font}`
  ctx.fillStyle = 'black'
  ctx.fillText(formatBrandName(brand.name), textX, textY)
  ctx.fillText(
    `${formatProductCode(code, style)} ${colorCode || ''}`,
    textX,
    textY + textH * lineHeight
  )

  // Product Thumbnail
  ctx.drawImage(thumbnailEl, thumbnailX, thumbnailY, thumbnailW, thumbnailH)

  // Screenshot
  ctx.drawImage(screenshotEl, screenshotX, screenshotY, screenshotW, screenshotH)

  // Brand Logo
  ctx.drawImage(brandLogoEl, brandLogoX, brandLogoY, brandLogoW, brandLogoH)

  return cvs
}

const downloadCanvas = (screenshotCanvas: HTMLCanvasElement, product: Product) => {
  const dataUrl = screenshotCanvas.toDataURL(config.imageType, config.imageQuality)
  const anchor = document.createElement('a')
  const filename = `${product.brand.name}_${product.name}`.replace(/[^a-z0-9]/gi, '_').toLowerCase()
  anchor.download = `${filename}.jpg`
  anchor.href = dataUrl
  anchor.style.visibility = 'hidden'
  document.body.appendChild(anchor)
  anchor.click()
  setTimeout(() => {
    document.body.removeChild(anchor)
  }, 0)
}

export const saveScreenshot = async (
  screenshot: VMScreenshotReturn,
  product: Product,
  style: string
): Promise<void> => {
  const screenshotCanvas = await createScreenshotCanvas(screenshot, product, style)
  downloadCanvas(screenshotCanvas, product)
}

export const getScreenshot = async (
  screenshot: VMScreenshotReturn,
  product: Product,
  style: string
): Promise<string> => {
  const screenshotCanvas = await createScreenshotCanvas(screenshot, product, style)
  return screenshotCanvas.toDataURL(config.imageType, config.imageQuality)
}

export default saveScreenshot
