import { Product } from 'vmdata'
import { VMCatalogItem } from 'virtualMirror'

const productsToMocos = (products: Product[]): VMCatalogItem[] =>
  products.map(product => ({
    upc: product.upc,
    label: product.name,
    brand: product.brand.name,
    thumbnailLink: product.thumbnailUrl,
    logo: product.brand.logoUrl,
    subtitle: product.subtitle
  }))

export default productsToMocos
