import React, { useState, useEffect } from 'react'
import styles from './App.module.scss'
import brandStyles from './Brands.module.scss'

import { VirtualMirror } from '@abstract/virtual-mirror'
import { saveScreenshot, getScreenshot } from './libs/saveScreenshot'
import { isIOS } from './libs/deviceDetection'
import getVMData, { isValidModel } from './libs/getVMData'
import { VMData } from 'vmdata'
import productsToMocos from './libs/productsToMocos'

const ImageWrapper = ({ src, setSrc }) => (
  <div className={styles['image-wrapper']}>
    <button onClick={() => setSrc(undefined)} className={styles['image-button']}>
      Close
    </button>
    <img src={src} alt="" className={styles['image-img']} />
  </div>
)

const ErrorContentWrapper = ({ error }) => (
  <div className={styles['error-wrapper']}>
    <div className={styles['error-text']}>{error}</div>
  </div>
)

const LoadingWrapper = () => (
  <div className={styles['loading-wrapper']}>
    <div className={styles['loading-text']}>Loading</div>
  </div>
)

export const VirtualMirrorWrapper = ({ store, style, products, setError }) => {
  const [currentUpc, setCurrentUpc] = useState<string>(products[0].upc)
  const [savedImageSrc, setSavedImageSrc] = useState<string>()
  const [VMSession, setVMSession] = useState<number>(new Date().getTime())

  const VMChannel = 'MY-LUXOTTICA-FOR-CONSUMERS'

  const VMBrandMap = {
    oo: 'OO',
    rb: 'RB',
    multi: 'MULTIBRAND',
  }

  const VMStoreId = `${store.storeId}-${store.id}`
  const VMBrand = VMBrandMap[store.type]

  const handleSaveScreenshot = async screenshot => {
    try {
      const product = products.find(product => product.upc === currentUpc)

      if (isIOS()) {
        setSavedImageSrc(await getScreenshot(screenshot, product, style))
        setVMSession(new Date().getTime())
      } else {
        await saveScreenshot(screenshot, product, style)
      }
    } catch (error) {
      console.error(error)
      setError('error during image download. refresh the page')
    }
  }

  return (
    <div className={`${styles['virtual-mirror']} ${brandStyles[style] || ''}`}>
      <VirtualMirror
        key={VMSession}
        brand={VMBrand}
        channel={VMChannel}
        fitMeBetterText={'FIT ME BETTER'}
        locale={'en-US'}
        mocos={productsToMocos(products)}
        storeId={VMStoreId}
        upc={currentUpc}
        onChangeUPC={setCurrentUpc}
        onGetScreenshot={handleSaveScreenshot}
        forceSquareAspectRatio
      />
      {savedImageSrc && <ImageWrapper src={savedImageSrc} setSrc={setSavedImageSrc} />}
    </div>
  )
}

const App = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [vmdata, setVmdata] = useState<VMData | undefined>()
  const [error, setError] = useState<string>()

  useEffect(() => {
    getVMData()
      .then((vmdata: VMData) => {
        if (isValidModel(vmdata)) {
          // Force style for debug purpose
          if (process.env.NODE_ENV === 'development') {
            try {
              const urlParams = new URLSearchParams(window.location.search)
              vmdata.style = urlParams.get('style')
            } catch (e) {}
          }

          setVmdata(vmdata)
          setIsLoading(false)
        } else {
          throw 'please provide a valid token'
        }
      })
      .catch(error => {
        setError(error.toString())
      })
  }, [])

  if (error) {
    return <ErrorContentWrapper error={error} />
  }

  if (isLoading) {
    return <LoadingWrapper />
  }

  return <VirtualMirrorWrapper {...vmdata} setError={setError} />
}

export default App
